import React from "react";

const Experience = () => {
  return (
    <div
      style={{ color: "white", alignItems: "center", justifyContent: "center" }}
    >
      Experience
    </div>
  );
};

export default Experience;
