import { List, ListItem, ListItemText, Toolbar } from "@mui/material";
import React from "react";
import logo from "../../images/logo-black.png";
import { NavLink } from "react-router-dom";
const Navbar = () => {
  return (
    <div style={{ position: "fixed", width: "100%" }}>
      <Toolbar>
        <NavLink to="/">
          <img src={logo} alt="logo" style={{ paddingTop: "10px" }} />
        </NavLink>
        <List
          style={{
            display: "flex",
            color: "#FFDF6C",
            fontSize: "25px",
            fontWeight: "bold",
            fontFamily: "Josefin Sans",
            marginLeft: "auto",
          }}
          component="nav"
          aria-label="main navigation"
        >
          <ListItem component={NavLink} to="/exprience">
            <ListItemText
              primaryTypographyProps={{
                fontSize: "25px",
                marginRight: 5,
                color: "#FFDF6C",
                fontFamily: "Josefin Sans",
              }}
              primary="Experience"
            />
          </ListItem>
          <ListItem component={NavLink} to="/showcase">
            <ListItemText
              primaryTypographyProps={{
                fontSize: "25px",
                marginRight: 5,
                color: "#FFDF6C",
                fontFamily: "Josefin Sans",
              }}
              primary="Showcase"
            />
          </ListItem>
          <ListItem component={NavLink} to="/contact">
            <ListItemText
              primaryTypographyProps={{
                fontSize: "25px",
                marginRight: 5,
                color: "#FFDF6C",
                fontFamily: "Josefin Sans",
              }}
              primary="Contact"
            />
          </ListItem>
        </List>
      </Toolbar>
    </div>
  );
};

export default Navbar;
