import React from "react";
import styles from "./HomeContent.module.scss";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import { MailOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import sound from "../../assets/mouseclick.mp3";

const HomeContent = () => {
  const handleClick = (url) => {
    const audio = new Audio(sound);
    audio
      .play()
      .then(() => {
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.log("Error playing sound");
        window.open(url, "_blank");
      });
  };
  return (
    <div
      className={styles.HomeContainer}
      style={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ width: "50%", paddingLeft: "15%" }}>
        <div style={{ fontSize: 64 }}>
          <p className={styles.GreetText}>Namaste!</p>
          <p className={styles.ImText}>I'm Divya Maurya</p>
        </div>
        <p className={styles.DevText}>
          A Passionate{" "}
          <span style={{ color: "#efefef", fontWeight: "bold" }}>
            Frontend Developer
          </span>{" "}
          with a knack for creating visually stunning and user-friendly web
          applications
        </p>
        <div>
          <IconButton onClick={() => handleClick("mailto:hello@divyad.dev")}>
            <MailOutline
              fontSize="inherit"
              style={{
                color: "red",
                fontSize: 30,
              }}
            />
          </IconButton>
          <IconButton
            onClick={() =>
              handleClick("https://www.linkedin.com/in/divyamaurya/")
            }
            target="_blank"
          >
            <LinkedInIcon
              fontSize="inherit"
              style={{
                color: "red",
                fontSize: 30,
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => handleClick("https://github.com/divyamaurya")}
          >
            <GitHubIcon
              fontSize="inherit"
              style={{
                color: "red",
                fontSize: 30,
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => handleClick("https://x.com/divyamauryadev")}
          >
            <XIcon
              fontSize="inherit"
              style={{
                color: "red",
                fontSize: 30,
              }}
            />
          </IconButton>
          <IconButton
            onClick={() =>
              handleClick("https://www.instagram.com/divyamaurya2")
            }
          >
            <InstagramIcon
              fontSize="inherit"
              style={{
                color: "red",
                fontSize: 30,
              }}
            />
          </IconButton>
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <lottie-player
          src="https://assets3.lottiefiles.com/packages/lf20_T4XJOLEPj5.json"
          background="transparent"
          speed="1"
          style={{ width: 600, height: 600 }}
          loop
          autoplay
        ></lottie-player>
      </div>
    </div>
  );
};

export default HomeContent;
