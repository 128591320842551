import { CssBaseline } from "@mui/material";
import "./App.css";
import Contact from "./components/Contact";
import HomeContent from "./components/home/HomeContent";
import Navbar from "./components/navbar/Navbar";
import Portfolio from "./components/showcase/Showcase";
import Experience from "./components/experience/Experience";
import Showcase from "./components/showcase/Showcase";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <CssBaseline />
          <header className="App-header">
            <Navbar />
            <Routes>
              <Route path="/" element={<HomeContent />} />
              <Route path="/experience" element={<Experience />} />
              <Route path="/showcase" element={<Showcase />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </header>
        </div>
      </Router>
    </>
  );
}

export default App;
