import React from "react";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import { MailOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
const Contact = () => {
  return (
    <div
      style={{
        color: "red",
        display: "flex",
        width: "60%",
        height: "100vh",
        margin: "0 auto",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <IconButton
        onClick={() => window.open("mailto:hello@divyad.dev", "_blank")}
      >
        <MailOutline
          fontSize="inherit"
          style={{
            color: "red",
            fontSize: 50,
          }}
        />
      </IconButton>
      <IconButton
        onClick={() =>
          window.open("https://www.linkedin.com/in/divyamaurya/", "_blank")
        }
        target="_blank"
      >
        <LinkedInIcon
          fontSize="inherit"
          style={{
            color: "red",
            fontSize: 50,
          }}
        />
      </IconButton>
      <IconButton
        onClick={() => window.open("https://github.com/divyamaurya", "_blank")}
      >
        <GitHubIcon
          fontSize="inherit"
          style={{
            color: "red",
            fontSize: 50,
          }}
        />
      </IconButton>
      <IconButton
        onClick={() =>
          window.open("https://twitter.com/mauryadivya2", "_blank")
        }
      >
        <XIcon
          fontSize="inherit"
          style={{
            color: "red",
            fontSize: 50,
          }}
        />
      </IconButton>
      <IconButton
        onClick={() =>
          window.open("https://www.instagram.com/divyamaurya2", "_blank")
        }
      >
        <InstagramIcon
          fontSize="inherit"
          style={{
            color: "red",
            fontSize: 50,
          }}
        />
      </IconButton>
    </div>
  );
};

export default Contact;
